///////////////////////////////////////////////////////////////////////////////////MODULES
import { useState, useRef, useEffect } from "react";
import { connect } from "react-redux";
import {
  FormGroup,
  Label,
  CustomButton,
  CustomInput,
  ButtonGroup,
  Spinner,
  Form,
  ListGroupItemHeading,
  CustomListGroup,
  CustomListGroupItem,
  InputGroup,
  Offcanvas,
  OffcanvasHeader,
  OffcanvasBody,
  ListGroupItemText,
  CustomPagination,
} from "@ibiliaze/reactstrap";
import { Api } from "@ibiliaze/react-base";
import { FullPage, Section } from "@ibiliaze/react-base";
import { saveAs } from "file-saver";
///////////////////////////////////////////////////////////////////////////////////ACTIONS
import { setAlert } from "../../actions/alert";
import { getQuotes, postQuote, putQuote, deleteQuote } from "../../actions/quotes";
/////////////////////////////////////////////////////////////////////////////////////UTILS
import { resolveHost } from "../../utils/resolveEnv";
import getRandomString from "../../utils/random";
import c from "../../utils/constants";
////////////////////////////////////////////////////////////////////////////////COMPONENTS
import Quote from "../Layout/Quote";
///////////////////////////////////////////////////////////////////////////////////////API
const api = new Api(resolveHost()).api;
//////////////////////////////////////////////////////////////////////////////////////////

const inputsDefault = {
  // DO NOT CHANGE THESE, BUT YOU CAN ADD
  model: "gpt-3.5-turbo-0125",
  temprature: 1,
  top_p: 0.5,
  max_tokens: 1400,
  name: "",
  address: "",
  postcode: "",
  time: "",
  details: "",
  email: "",
  rci: "yes",
  instalments: 3,
  invoice: false,
};

const AdminPage = ({ quotes, getQuotes, postQuote, putQuote, deleteQuote, setAlert }) => {
  // State
  const [page, setPage] = useState(1);
  const [offcanvas, setOffcanvas] = useState(false);
  const [loading, setLoading] = useState(false);
  const [edit, setEdit] = useState(false);
  const [generated, setGenerated] = useState("");
  const [selectedQuote, setSelectedQuote] = useState("New");
  const [quoteRefSearch, setQuoteRefSearch] = useState("");
  const [ref, setRef] = useState("");
  const [quoteUrl, setQuoteUrl] = useState("");
  const [details, setDetails] = useState([]);
  const [inputs, setInputs] = useState({ ...inputsDefault });

  // Ref
  const componentRef = useRef();

  // Toggle functions
  const toggleOffcanvas = () => setOffcanvas(!offcanvas);

  // onChange functions
  const onInputsChange = (e) => setInputs((c) => ({ ...c, [e.target.name]: e.target.value }));
  const onQuoteRefSearchChange = (e) => setQuoteRefSearch(e.target.value);

  // onClick functions
  const onPageClick = async (e, index) => {
    try {
      e.preventDefault();
      const goToPage = index < 1 ? 1 : index;

      setPage(goToPage);
      await getQuotes(`?ref=${quoteRefSearch}&limit=10&skip=${20 * (goToPage - 1)}`);
    } catch (e) {
      console.error(e);
    }
  };

  const onQuoteClick = (quote) => {
    try {
      setSelectedQuote(!!quote ? quote._id : "New");

      if (!!quote) {
        setInputs({ ...quote.inputs });
        setDetails([...quote.details]);
        setRef(quote.ref);
      } else {
        setInputs({ ...inputsDefault });
        setDetails([]);
        setRef("");
      }
    } catch (e) {
      console.error(e);
      setSelectedQuote("New");
      setInputs({ ...inputsDefault });
      setDetails([]);
      setRef("");
    }
  };

  const onGenerateClick = async (_) => {
    try {
      setLoading(true);
      const res = await api.post("/api/quote/generate", { ...inputs });
      setGenerated(res.data.quote);
      setLoading(false);
    } catch (e) {
      setLoading(false);
      console.error(e);
    }
  };

  const onAddClick = (_) => {
    try {
      setDetails((c) => [...c, { input: inputs.details, details: generated }]);

      setInputs((c) => ({ ...c, details: "" }));
      setGenerated("");
      setEdit(false);
    } catch (e) {
      setEdit(false);
      console.error(e);
    }
  };

  const onDetailClick = (det, i) => {
    try {
      setEdit(i + 1);
      setInputs((c) => ({ ...c, details: det.input }));
      setGenerated(det.details);
    } catch (e) {
      setEdit(false);
      console.error(e);
    }
  };

  const onDeleteDetailClick = (_) => {
    try {
      const arr = details;
      arr.splice(edit - 1, 1);
      setDetails([...arr]);

      setInputs((c) => ({ ...c, details: "" }));
      setGenerated("");
      setEdit(false);
    } catch (e) {
      setEdit(false);
      console.error(e);
    }
  };

  const onCreateClick = async (_) => {
    try {
      setLoading(true);
      const res = await api.post("/api/quote/create", {
        ...inputs,
        dom: componentRef.current.innerHTML,
      });

      const fileUrl = res.data.url;
      const fileName = `Quote for ${inputs.name} - ${inputs.postcode} - ${ref}`;
      const response = await fetch(fileUrl);
      const blob = await response.blob();
      saveAs(blob, fileName);

      setQuoteUrl(fileUrl);

      setLoading(false);
    } catch (e) {
      setLoading(false);
      setQuoteUrl("");
      console.error(e);
    }
  };

  const onSearchClick = async (e) => {
    try {
      await getQuotes(`?ref=${quoteRefSearch}&limit=10&skip=${20 * (page - 1)}`);
      toggleOffcanvas();
    } catch (e) {
      console.error(e);
    }
  };

  const onEmailClick = async (e) => {
    try {
      if (!quoteUrl) {
        return setAlert("Quote has not been created", true, null);
      }

      setLoading(true);
      const res = await api.post("/api/quote/send", {
        to: inputs.email,
        name: inputs.name,
        invoice: inputs.invoice,
        attachments: c.extras.attachments,
        quoteUrl,
      });

      setAlert(res.data.message, false, null);

      setLoading(false);
    } catch (e) {
      setLoading(false);
      console.error(e);
    }
  };

  const onDeleteQuoteClick = async (e) => {
    try {
      await deleteQuote(selectedQuote);
      setSelectedQuote("New");
      setInputs({ ...inputsDefault });
      setDetails([]);
      setRef("");
    } catch (e) {
      console.error(e);
    }
  };

  const onInvoiceClick = async (e) => {
    try {
      setInputs((c) => ({ ...c, invoice: !c.invoice }));
    } catch (e) {
      console.error(e);
    }
  };

  const onSaveClick = async (e) => {
    try {
      await putQuote({ quoteId: selectedQuote, invoice: inputs.invoice, ref, inputs, details });
    } catch (e) {
      console.error(e);
    }
  };

  // Lifecycle hooks
  useEffect(() => {
    try {
      if (selectedQuote === "New") {
        setRef(getRandomString(4));
      }
    } catch (e) {
      console.error(e);
    }
  }, [selectedQuote]);

  // onSubmit function
  const onSubmit = async (e) => {
    e.preventDefault();

    try {
      await postQuote({ ref, inputs, details });
    } catch (e) {
      console.error(e);
    }
  };

  // JSX
  return (
    <FullPage>
      <Offcanvas direction="start" fade scrollable isOpen={offcanvas} toggle={toggleOffcanvas}>
        <OffcanvasHeader toggle={toggleOffcanvas}>Quotes</OffcanvasHeader>
        <OffcanvasBody>
          <CustomPagination page={page} onPageClick={onPageClick} />

          <CustomListGroup>
            {quotes.map((q, i) => (
              <CustomListGroupItem key={i} onClick={(_) => onQuoteClick(q)}>
                <ListGroupItemText>{q.ref}</ListGroupItemText>
              </CustomListGroupItem>
            ))}
          </CustomListGroup>
        </OffcanvasBody>
      </Offcanvas>

      <Section className="below-header custom-page p-t-m">
        <FormGroup>
          <InputGroup>
            <CustomInput placeholder="Quote Ref" value={quoteRefSearch} onChange={onQuoteRefSearchChange} />
            <CustomButton color="primary" black onClick={onSearchClick}>
              Search
            </CustomButton>
            <CustomButton color="primary" onClick={onQuoteClick}>
              New Quote
            </CustomButton>
          </InputGroup>
        </FormGroup>

        <hr />

        {selectedQuote !== "New" && (
          <>
            <InputGroup>
              <CustomInput
                required
                type="text"
                name="email"
                value={inputs.email}
                onChange={onInputsChange}
                placeholder="recipient@example.com"
              />
              <CustomButton
                disabled={loading ? true : !quoteUrl ? true : false}
                color="primary"
                black
                onClick={onEmailClick}
              >
                {loading ? <Spinner type="grow" size="sm" /> : "Send Email"}
              </CustomButton>
            </InputGroup>
            <br />

            <ButtonGroup>
              <CustomButton color="primary" onClick={onInvoiceClick}>
                Turn to {inputs.invoice ? "Quote" : "Invoice"}
              </CustomButton>
              <CustomButton color="primary" black onClick={onSaveClick}>
                Save
              </CustomButton>
              <CustomButton color="danger" onClick={onDeleteQuoteClick}>
                Delete
              </CustomButton>
            </ButtonGroup>

            <br />
            <br />
          </>
        )}
        <Form onSubmit={onSubmit}>
          <FormGroup floating>
            <CustomInput
              required
              placeholder="Model *"
              type="select"
              name="model"
              value={inputs.model}
              onChange={onInputsChange}
            >
              <option>gpt-3.5-turbo-0125</option>
              <option>gpt-4</option>
            </CustomInput>
            <Label>Model *</Label>
          </FormGroup>

          <FormGroup floating>
            <CustomInput
              required
              placeholder="Temprature *"
              type="number"
              name="temprature"
              value={inputs.temprature}
              onChange={onInputsChange}
            />
            <Label>Temprature *</Label>
          </FormGroup>

          <FormGroup floating>
            <CustomInput
              required
              placeholder="Top P *"
              type="number"
              name="top_p"
              value={inputs.top_p}
              onChange={onInputsChange}
            />
            <Label>Top P *</Label>
          </FormGroup>

          <FormGroup floating>
            <CustomInput
              required
              placeholder="Max Tokens *"
              type="number"
              name="max_tokens"
              value={inputs.max_tokens}
              onChange={onInputsChange}
            />
            <Label>Max Tokens *</Label>
          </FormGroup>

          <FormGroup floating>
            <CustomInput
              required
              placeholder="Name *"
              type="text"
              name="name"
              value={inputs.name}
              onChange={onInputsChange}
            />
            <Label>Name *</Label>
          </FormGroup>

          <FormGroup floating>
            <CustomInput
              required
              placeholder="Address *"
              type="text"
              name="address"
              value={inputs.address}
              onChange={onInputsChange}
            />
            <Label>Address *</Label>
          </FormGroup>

          <FormGroup floating>
            <CustomInput
              required
              placeholder="Postcode *"
              type="text"
              name="postcode"
              value={inputs.postcode}
              onChange={onInputsChange}
            />
            <Label>Postcode *</Label>
          </FormGroup>

          <FormGroup floating>
            <CustomInput
              required
              placeholder="Estimated Time *"
              type="text"
              name="time"
              value={inputs.time}
              onChange={onInputsChange}
            />
            <Label>Estimated Time *</Label>
          </FormGroup>

          <FormGroup floating>
            <CustomInput required type="select" name="rci" value={inputs.rci} onChange={onInputsChange}>
              <option>yes</option>
              <option>no</option>
            </CustomInput>
            <Label>Rubbish collection included *</Label>
          </FormGroup>

          <FormGroup floating>
            <CustomInput
              required
              type="number"
              name="instalments"
              value={inputs.instalments}
              onChange={onInputsChange}
            />
            <Label>Instalments *</Label>
          </FormGroup>

          <br />
          <CustomListGroup>
            {details.map((det, i) => (
              <CustomListGroupItem key={i} onClick={(_) => onDetailClick(det, i)}>
                <ListGroupItemHeading>{det.details.header}</ListGroupItemHeading>
              </CustomListGroupItem>
            ))}
          </CustomListGroup>
          <br />

          <FormGroup>
            <Label>Details</Label>
            <CustomInput
              placeholder="Details"
              type="textarea"
              rows={10}
              name="details"
              value={inputs.details}
              onChange={onInputsChange}
            />
          </FormGroup>

          {!!generated && typeof generated === "object" ? (
            <pre>
              {JSON.stringify(generated, null, 2)}
              <br />
            </pre>
          ) : (
            <></>
          )}

          <ButtonGroup>
            <CustomButton disabled={loading ? true : false} color="primary" black onClick={onGenerateClick}>
              {loading ? <Spinner type="grow" size="sm" /> : "Generate"}
            </CustomButton>
            <CustomButton color="primary" onClick={onAddClick}>
              Add to Quote
            </CustomButton>
            {!!edit ? (
              <>
                <CustomButton color="danger" onClick={onDeleteDetailClick}>
                  Delete
                </CustomButton>
              </>
            ) : (
              <></>
            )}
          </ButtonGroup>

          <br />
          <br />

          <CustomButton type="submit" disabled={loading ? true : false} color="primary" black>
            {loading ? <Spinner type="grow" size="sm" /> : "SAVE QUOTE"}
          </CustomButton>
          <br />
          <br />
        </Form>

        <div className="p-m" style={{ background: "white" }}>
          <div ref={componentRef}>
            <Quote details={details} inputs={inputs} id={ref} />
          </div>
        </div>

        <br />

        <CustomButton disabled={loading ? true : false} color="primary" black onClick={onCreateClick}>
          {loading ? <Spinner type="grow" size="sm" /> : "CREATE DOCUMENT"}
        </CustomButton>

        <br />
        <br />
      </Section>
    </FullPage>
  );
};

const mapStateToProps = (state) => ({ quotes: state.quotes });

export default connect(mapStateToProps, {
  setAlert,
  getQuotes,
  postQuote,
  putQuote,
  deleteQuote,
})(AdminPage);
