////////////////////////////////////////////////////////////////////////////////ITEM TYPES
export const GET_ITEMS_SUCCESS = "GET_ITEMS_SUCCESS";
export const GET_ITEMS_FAILURE = "GET_ITEMS_FAILURE";
export const POST_ITEM_SUCCESS = "POST_ITEM_SUCCESS";
export const POST_ITEM_FAILURE = "POST_ITEM_FAILURE";
export const PUT_ITEM_SUCCESS = "PUT_ITEM_SUCCESS";
export const PUT_ITEM_FAILURE = "PUT_ITEM_FAILURE";
export const DELETE_ITEM_SUCCESS = "DELETE_ITEM_SUCCESS";
export const DELETE_ITEM_FAILURE = "DELETE_ITEM_FAILURE";
export const RESET_BASKET_SUCCESS = "RESET_BASKET_SUCCESS";
export const RESET_BASKET_FAILURE = "RESET_BASKET_FAILURE";
///////////////////////////////////////////////////////////////////////////////ALERT TYPES
export const GET_MESSAGES = "GET_MESSAGES";
export const CLEAR_MESSAGES = "CLEAR_MESSAGES";
///////////////////////////////////////////////////////////////////////////////ADMIN TYPES
export const LOGIN_ADMIN_SUCCESS = "LOGIN_ADMIN_SUCCESS";
export const LOGIN_ADMIN_FAILURE = "LOGIN_ADMIN_FAILURE";
export const REGISTER_ADMIN_SUCCESS = "REGISTER_ADMIN_SUCCESS";
export const REGISTER_ADMIN_FAILURE = "REGISTER_ADMIN_FAILURE";
export const GET_ADMIN_SUCCESS = "GET_ADMIN_SUCCESS";
export const GET_ADMIN_FAILURE = "GET_ADMIN_FAILURE";
export const LOGOUT_ADMIN_SUCCESS = "LOGOUT_ADMIN_SUCCESS";
export const LOGOUT_ADMIN_FAILURE = "LOGOUT_ADMIN_FAILURE";
export const PUT_ADMIN_SUCCESS = "PUT_ADMIN_SUCCESS";
export const PUT_ADMIN_FAILURE = "PUT_ADMIN_FAILURE";
export const DELETE_ADMIN_SUCCESS = "DELETE_ADMIN_SUCCESS";
export const DELETE_ADMIN_FAILURE = "DELETE_ADMIN_FAILURE";
////////////////////////////////////////////////////////////////////////////QUOTE TYPES
export const GET_QUOTES_SUCCESS = "GET_QUOTES_SUCCESS";
export const GET_QUOTES_FAILURE = "GET_QUOTES_FAILURE";
export const POST_QUOTE_SUCCESS = "POST_QUOTE_SUCCESS";
export const POST_QUOTE_FAILURE = "POST_QUOTE_FAILURE";
export const PUT_QUOTE_SUCCESS = "PUT_QUOTE_SUCCESS";
export const PUT_QUOTE_FAILURE = "PUT_QUOTE_FAILURE";
export const DELETE_QUOTE_SUCCESS = "DELETE_QUOTE_SUCCESS";
export const DELETE_QUOTE_FAILURE = "DELETE_QUOTE_FAILURE";
///////////////////////////////////////////////////////////////////////////////EMAIL TYPES
export const CONFIRM_EMAIL_SUCCESS = "CONFIRM_EMAIL_SUCCESS";
export const CONFIRM_EMAIL_FAILURE = "CONFIRM_EMAIL_FAILURE";
export const PUSH_EMAIL_SUCCESS = "PUSH_EMAIL_SUCCESS";
export const PUSH_EMAIL_FAILURE = "PUSH_EMAIL_FAILURE";
//////////////////////////////////////////////////////////////////////////////////////////
