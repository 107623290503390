///////////////////////////////////////////////////////////////////////////////////MODULES
import { createStore, combineReducers, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";
//////////////////////////////////////////////////////////////////////////////////REDUCERS
import admin from "../reducers/admin";
import quotes from "../reducers/quotes";
import alert from "../reducers/alert";
//////////////////////////////////////////////////////////////////////////////////////////

const composeEnhancers =
  (typeof window !== "undefined" && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose;
const configureStore = () => {
  const store = createStore(
    combineReducers({ admin, quotes, alert }),
    composeEnhancers(applyMiddleware(thunk))
  );

  return store;
};

export default configureStore;
